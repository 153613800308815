import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component';
import Routes from './routes';
import 'react-notifications-component/dist/theme.css';
// import { Provider } from 'react-redux';
// import store from '~/store';

const App = () => <Routes />;

ReactDOM.render(<><ReactNotification /><App /></>, document.getElementById('root'));
