import React, { Suspense, lazy } from 'react';

import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './global.css';

// Import Pages
const Sis = lazy(() => import('./pages/Sis'));
const Website = lazy(() => import('./pages/site'));
const Jobs = lazy(() => import('./pages/Jobs'));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/sis" component={Sis} />
          <Route path="/jobs" component={Jobs} />

          <Route path="/" component={Website} />

        </Switch>
      </Suspense>
    </Router>
  );
}
